<template>
    <div class="auth-inner row m-0 d-flex align-items-center justify-content-center">
      <div class="col-md-6 align-items-center auth-bg px-2 p-lg-5 investigation-report">
          <div class="col-md-12 mx-auto pt-2">
            <div class="card">
              <div class="bg-blue-light p-1 mb-2">
                  <TitleButton 
                      :showBtn="false"
                      :showAddNew="false"
                      title="Investigation Reports"
                  />
              </div>

              <div class="col-12 px-2 mt-2">
                <ListTable 
                    :investigations="investigations"
                />
              </div>

              <Loader v-if="loading"/>
            </div>
          </div>
      </div>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton.vue';
import ListTable from '@/components/public/investigation/InvestigationReportTable.vue';
import {useRoute, useRouter} from "vue-router";
import handleHospital from "@/services/modules/hospital";
import {computed, inject, onMounted, ref} from "vue";
import Loader from "@/components/atom/LoaderComponent.vue";
import {useStore} from "vuex";

const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const { fetchInvestigationGeneralReports } = handleHospital();
const loading = ref(false);
const investigations = ref([])
const companyId = computed(() => $route.params.companyId);

const getQuery = () => {
    let query = '?company_id=' + companyId.value
    query += `&updated_only=1`
    return query
};

async function getInvestigationGenerals() {
  loading.value = true;
  const query = getQuery();
  const billId = $route.params.billId;
  try {
    const res = await fetchInvestigationGeneralReports(query, billId);
    if (!res.status) {
      investigations.value = [];
      return;
    }
    investigations.value = res.data;
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    loading.value = false;
  }
}

onMounted( async () => {
   await getInvestigationGenerals()
})
</script>

<style scoped>
.investigation-report {
    width: 380px !important;
}
</style>