<template>
    <div class="card">
        <div class="auth-inner row m-0 d-flex align-items-center justify-content-center">
            <div class="d-flex col-md-6 align-items-center auth-bg px-2 p-lg-5 p-5">
                <div class="col-md-12 mx-auto pt-2">

                    <div class="brand-logo">
                        <a class="nav-link" href="javascript:void(0)">
                            <img :src="companyInfo ? companyInfo.logo : ''">
                            <h2 class="mb-0 mt-1">{{ companyInfo ? companyInfo.name : '' }}</h2>
                        </a>
                    </div>

                    <p class="card-text mb-2">Online Investigation Report Center</p>

                    <form class="auth-login-form mt-2" method="POST" @submit.prevent="onSubmit()">
                        <div class="mb-1">
                            <label class="form-label" for="mobile_no">Phone Number</label>
                            <input
                                v-model.trim="formData.mobile_no"
                                class="form-control"
                                type="text"
                                name="mobile_no"
                                placeholder="Phone Number"
                                autofocus="" 
                                tabindex="1"
                                @input="formError.mobile_no = ''"
                            />
                            <span class="text-danger" v-if="formError.mobile_no">{{ formError.mobile_no }}</span>
                        </div>
                        <button :disabled="isLoading" class="btn btn-primary w-100" tabindex="4">
                            <span v-if="isLoading" class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
                            {{ isLoading ? 'Please wait...' : 'Submit' }}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { inject, ref } from 'vue';
import { useRoute } from 'vue-router';
import handleHospital from "@/services/modules/hospital";

const props = defineProps({
    companyInfo: {
        type: Object || null
    },
    onStepChange: {
        type: Function,
        default: () => {}
    }
})

const emit = defineEmits(['onStepChange']);

const route = useRoute();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const { checkBillingInfo } = handleHospital();

const isLoading = ref(false);
const formData = ref({
  company_id: route.params.companyId,
  sale_master_id: route.params.billId,
  mobile_no: '',
});

const formError = ref({
  mobile_no: '',
});

const onSubmit = async () => {
  if(! checkValidation()) {
    return;
  }

  isLoading.value = true;

  await checkBillingInfo(formData.value).then(async(res) => {
    if (!res.status) {
      showError(res.message);
      return;
    }
    showSuccess(res.message);
    emit('onStepChange', 2)
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    isLoading.value = false
  })
};

const checkValidation = () => {
  if(! formData.value.mobile_no) {
    formError.value.mobile_no = 'Please enter mobile number';
    return false;
  }

  return true;
}
</script>

<style scoped>
.brand-logo {
  text-align: center;
}

.brand-logo img {
  height: 45px;
}
</style>