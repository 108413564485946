<template>
    <div class="card investigation-card">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    <tr>
                        <th>Bill no</th>
                        <th>Report name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in investigations" :key="i">
                        <td>{{ item.bill_number }}</td>
                        <td>{{ convertToTitleCase(item.report_name) }}</td>
                        <td>
                            <button v-if="item.is_updated" @click="goToPrint(item)" class="btn btn-secondary btn-sm">
                                <i class="fas fa-print"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p v-if="!investigations.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
import handleBarcode from "@/services/modules/barcode";
import {useRoute} from "vue-router";
import handleHospital from "@/services/modules/hospital";
import investigationResultPdfPrinter from '@/services/utils/investigationResultPdfPrinter';
import { inject, onMounted, ref } from "vue";
import handleCompany from "@/services/modules/company";
import { useStore } from "vuex";
import { formatDate, convertToTitleCase } from '@/services/utils/global';

const props = defineProps({
    investigations: Array,
})

const { fetchBarcodeTwo } = handleBarcode();
const { fetchSaleGeneralDetails } = handleHospital();
const {fetchCompanyDetails} = handleCompany();
const { exportToPDF } = investigationResultPdfPrinter();
const $route = useRoute();
const $store = useStore();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const investigationDetails = ref({});
const companyInfo = ref({});
const barcode = ref('');
const qrcode = ref('');
const barcodePatient = ref('');

const getQuery = (investigation) => {
    let query = `?company_id=${$route.params.companyId}`;
    query += `&investigation_type=${investigation.investigation_type}`;
    query += `&investigation_ids=${investigation.investigation_general_ids}`;
    return query;
}

const goToPrint = async (investigation) => {
    const query = getQuery(investigation);

    await fetchSaleGeneralDetails(query, investigation.sale_master_id)
    .then((res) =>{
        if (!res.status) return showError(res.message)
        investigationDetails.value = res.data
    })
    .then( () => {
        generatePdf(companyInfo.value, investigationDetails.value, investigation)
    }).catch( (err) => {
        console.log(err);
    })
}

const generatePdf = async (company, data, investigation) => {
    const user = $store.state.user ? $store.state.user.user : '';
    const isHideFooter = true;
    const isHideHeader = true;
    const isDownload = true;

    let qrCodeData = JSON.stringify({
        ref_no: data.id,
        ref_date: data.date,
        created_by: user ? user.name : '',
        created_date_time: formatDate(new Date())
    })

    const barCodeQuery = `?code=${investigation.investigation_general_ids}&qrcode=${qrCodeData}`;
    const barCodePatientQuery = `?code=${data.contact_profile_id}`;

    await Promise.all([
        fetchBarcodeTwo(barCodeQuery).then((res) => {
            barcode.value = res.barcode;
            qrcode.value = res.qrcode;
        }),
        fetchBarcodeTwo(barCodePatientQuery).then((res) => {
            barcodePatient.value = res.barcode;
        })
    ]).then(() => {
        exportToPDF(
            company,
            data,
            barcode.value,
            qrcode.value,
            barcodePatient.value,
            '',
            convertToTitleCase(investigation.report_name),
            isHideHeader,
            isHideFooter,
            isDownload
        )
    }).catch((e) => {
        console.log(e.message);
    })
}

const getCompanyInfo = async () => {
    const companyId = $route.params.companyId;

    await fetchCompanyDetails(companyId)
    .then((res) => {
        if(res.status){
            companyInfo.value = res.data;
        }
    }).catch((error) => {
        console.log(error);
    });
}

onMounted( async () => {
    await getCompanyInfo();
})
</script>

<style scoped>
.edit-btn{
    margin-right: 1rem;
}
.investigation-card{
    min-height: 200px
}
.image-container{
    width: 20px;
}
.avatar-image{
    width: 65px;
}
.patient-detail-th{
    width: 30%;
}
</style>
