<template>
  <div class="app-content content m-0">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-body">
        <div class="auth-wrapper auth-cover">

          <NumberConfirmation 
            :companyInfo="companyInfo"
            v-if="step === 1"
            @onStepChange="onStepChange"
          />

          <InvestigationReport
            v-if="step === 2"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import handleCompany from "@/services/modules/company";
import NumberConfirmation from '@/components/public/investigation/NumberConfirmation.vue';
import InvestigationReport from '@/components/public/investigation/InvestigationReport.vue';
import fetchUser from '@/services/modules/login'

const { setPublicTokenInLocalStorage } = fetchUser();

const route = useRoute();
const {fetchCompanyDetails} = handleCompany();
const companyInfo = ref(null);
const step = ref(1);

const onStepChange = (val) => {
  step.value = val;
}

const getCompanyInfo = async () => {
  await fetchCompanyDetails(route.params.companyId).then( (res) => {
    companyInfo.value = res.data;
  })
}

const setPublicToken = () => {
  if (!route.query.public_token) return;
  setPublicTokenInLocalStorage(route.query.public_token);
}

onMounted(() => {
  setPublicToken();
  getCompanyInfo();
})
</script>
